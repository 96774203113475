.form-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: gray;
  }
  
  input,
  select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #333;
  }
  
  .error {
    color: red;
    font-size: 12px;
  }
  
  .success-message {
    color: green;
    text-align: center;
    margin-top: 20px;
  }
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root{
    color:'red';
  }