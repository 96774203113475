@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
  font-family:'Rubik', sans-serif;
  background-color: #212121!important;
  color: #fff!important ;
  font-size: 1rem;
  line-height: 1.5
}
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #212121;
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: #212121;
}

::-webkit-scrollbar-thumb
{
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.3);
	background-color: #ccc;
}